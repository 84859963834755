import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../Layout/layout';


const BackLink = styled(Link)`
    font-size: 0.8rem;
    text-decoration: underline;
    color: #524763;
`
class PostLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data;
    const { location } = this.props;
    return (
      <Layout location={location}>
        <div dangerouslySetInnerHTML={{
          __html: markdownRemark.html
        }} />
        <BackLink to="/blog">Go back to the main blog</BackLink>
      </Layout>
    )
  }
}

export default PostLayout

export const query = graphql`
query PostQuery($slug: String!) {
  markdownRemark(frontmatter: {
    slug: {
      eq: $slug
    }
  }) {
    html
    frontmatter {
      title
      date
      slug
    }
  }
}
`